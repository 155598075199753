<template>
  <div class="container">
    <div class="is-size-4 center">
      Kolory i rodzaje tapicerek utworzone w serwisie
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminUpholsteries",
};
</script>

<style lang="scss" scoped>
</style>